<template>
  <div class="d-flex flex-column">
    <task-card :id="taskId" @hasCompany="hasCompany = $event"/>

    <b-row>
      <b-col md="6" class="mt-7">
        <comments-list entity="task" :entity-id="taskId"/>
      </b-col>

      <b-col md="6" class="mt-7">
        <app-card>
          <template #title>{{ $t('label.history') }}</template>
          <template #body>
            <app-history
                entity="task"
                :request-url="`api/tasks/${taskId}/history`"
            />
          </template>
        </app-card>
      </b-col>
    </b-row>

    <portal to="subheader-toolbar">
      <b-button class="mr-3" variant="transparent-white" @click="$router.push({name: 'tasksList'})">
        {{ $t('btn.back_to_list') }}
      </b-button>
      <b-button class="mr-3" variant="transparent-white" @click="goToTicket">
        {{ $t('btn.back_to_ticket') }}
      </b-button>
      <b-button v-if="hasCompany" class="mr-3" variant="outline-warning" v-b-modal.send-task-modal>
        {{ $t('btn.send') }}
      </b-button>
      <b-button class="mr-3" variant="danger" v-b-modal.delete-task-modal>
        {{ $t('btn.delete') }}
      </b-button>
      <router-link :to="{name: 'updateTask', params: {id: taskId, ticketId: ticketId}}">
        <a :href="`/tickets/${ticketId}/tasks/${taskId}/update`" class="btn btn-light-success">
          {{ $t('btn.update') }}
        </a>
      </router-link>
    </portal>
    <app-modal
        id="delete-task-modal"
        :title="$t('label.confirm_action')"
        :ok-text="$t('btn.delete')"
        ok-variant="danger"
        @ok="deleteItem"
    >
      <template #app-modal-body>
        <span class="font-size-lg">{{ $t('modal.delete_task') }}?</span>
      </template>
    </app-modal>

    <app-modal
        id="send-task-modal"
        :title="$t('label.confirm_action')"
        :ok-text="$t('btn.send')"
        ok-variant="success"
        @ok="sendTask"
    >
      <template #app-modal-body>
        <span class="font-size-lg">{{ $t('modal.choose_sending_type') }}?</span>
        <b-form-group class="mt-5">
          <b-form-radio-group
              v-model="selectedSendType"
              :options="sendingOptions"
          ></b-form-radio-group>
        </b-form-group>
      </template>
    </app-modal>
  </div>
</template>

<script>
import AppCard from '@/components/AppCard';
import AppHistory from '@/components/history/AppHistory';
import AppModal from '@/components/modals/AppModal';
import CommentsList from '@/components/comments/CommentsList';
import TaskCard from './components/TaskCard';

export default {
  components: {
    AppCard,
    AppHistory,
    AppModal,
    CommentsList,
    TaskCard
  },
  data: () => ({
    taskId: null,
    ticketId: null,
    hasCompany: false,
    selectedSendType: 'all'
  }),
  beforeMount() {
    this.taskId = this.$route.params.id;
    this.ticketId = this.$route.params.ticketId;
  },
  computed: {
    sendingOptions() {
      return [
        {value: 'all', text: this.$t('label.all')},
        {value: 'sms', text: this.$t('label.sms')},
        {value: 'email', text: this.$t('label.email')}
      ]
    }
  },
  methods: {
    goToTicket() {
      this.$router.push({name: 'showTicket', params: {id: this.ticketId}});
    },
    goToUpdate() {
      this.$router.push({name: 'updateTask', params: {id: this.taskId, ticketId: this.ticketId}});
    },
    deleteItem() {
      this.$store.dispatch('tasksStore/DELETE', this.taskId).then(() => {
        this.$router.push({name: 'showTicket', params: {id: this.ticketId}});
      });
    },
    sendTask() {
      this.$store.dispatch('actsStore/SEND_TYPE', {taskId: this.taskId, type: this.selectedSendType})
    }
  }
}
</script>